<template>
    <v-app id="inspire">
      <div class="d-flex flex-grow-1" v-bind:class="(!drawer) ? 'fullscreen' : ''">
        <Nav v-if="this.login" :drawer="this.drawer"></Nav>
        <Header
            v-if="this.login"
            v-on:toggle_sidebar="toggleSidebar"
           ></Header>
        <v-main>
          <v-container class="container--fluid">
            <router-view :profile="profile"></router-view>
          </v-container>
        </v-main>
      </div>
    </v-app>
</template>
<script>
    import Nav from "./components/Nav";
    import Header from "./components/Header";
    import axios from "axios";
    export default {
      components: {Header, Nav},
      data: () => ({
        drawer: false,
        login: false,
        profile: null,
      }),
      mounted () {
        this.profileData();
      },
      watch: {
        '$route.name': {
          handler: function() {
            this.login = true;
            if (this.$route.name === 'Login') {
              this.login = false;
            }
          },
        },
      },
      methods: {
        toggleSidebar: function () {
          this.drawer = !this.drawer;
        },
        onResize: function () {
          this.drawer = window.innerWidth > 960;
        },
        profileData: function () {
          let self = this;
          let profileUrl = axios.defaults.baseURL + '/api/user';
          axios.get(profileUrl).then((response) => {
            document.body.classList.remove('profile-unloaded');
            this.profile = response.data;
          }).catch(function (error) {
            self.$router.push('/');
          });
          this.onResize();
        }
      }
    }
</script>