<template>
  <v-app-bar
      color="#ffffff"
      flat
      fixed
  >
    <v-app-bar-nav-icon @click="toggleSidebar">
    </v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <v-text-field
        hide-details
        prepend-icon="mdi-magnify"
        single-line
        v-model="searchTerm"
        @keydown.enter="searchShipment"
        placeholder="Otsi"
        clearable
        @click:clear="clearSearch"
    ></v-text-field>
    <v-spacer></v-spacer>
    <v-btn icon>
      <v-icon>mdi-bell-outline</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: "Header",
  data: () => ({
    searchTerm: '',
  }),
  watch: {
    '$route.params.search': {
      handler: function() {
        let searchQuery = this.$route.query.search;
        this.searchTerm = searchQuery;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    toggleSidebar: function () {
      this.$emit("toggle_sidebar");
    },
    searchShipment: function () {
      this.$router.push('/offers?search=' + this.searchTerm)
    },
    clearSearch: function () {
      this.$router.push('/offers')
    }
  },
}
</script>

<style scoped>

</style>