import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import './scss/main.scss'

import './plugins/apexcharts'
import './registerServiceWorker'

const axios = require('axios');

Vue.config.productionTip = false
Vue.prototype.$http = axios
axios.defaults.withCredentials = true
axios.defaults.baseURL = 'https://api.finsy.ee'
//axios.defaults.baseURL = 'http://localhost:8085'

const DEFAULT_TITLE = 'Finsy';
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

Vue.filter('toCurrency', function (value) {
  value = parseFloat(value);
  if (typeof value !== "number") {
    return value;
  }

  var formatter = new Intl.NumberFormat('et-EE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
