import Vue from 'vue'
import VueRouter from 'vue-router'
import VueBodyClass from 'vue-body-class';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: { title: 'Login', bodyClass: 'login-view' }
  },
  {
    path: '/offers',
    name: 'Offers',
    component: () => import('../views/Offers.vue'),
    meta: { title: 'Pakkumised' }
  },
  {
    path: '/policies',
    name: 'Policies',
    component: () => import('../views/Policies.vue'),
    meta: { title: 'Poliisid' }
  },
  {
    path: '/conditions',
    name: 'Conditions',
    component: () => import('../views/Conditions.vue'),
    meta: { title: 'Võrdlustabel' }
  },
  {
    path: '/conditions-new',
    name: 'Conditions',
    component: () => import('../views/AddCondition.vue'),
    meta: { title: 'Võrdlustabel' }
  },
  {
    path: '/condition/:conditionId',
    name: 'Condition',
    component: () => import('../views/EditCondition.vue'),
    props: true,
  },
];

const router = new VueRouter({
  routes
});

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => { vueBodyClass.guard(to, next) });

export default router
